import React from "react"
import SEO from "../components/seo"
import LayoutsComponent from '../layouts/LayoutsComponent';
import VerificacionTelefonica from '../components/pages/afi/VerificacionTelefonica'

const VerificacionTelefonicaPage = ({ pageContext, location }) => (

  <LayoutsComponent
    layout={pageContext.layout}
    broker={pageContext.broker}
    pageContext={pageContext}
    location={location}
    companyName={pageContext.company}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <VerificacionTelefonica 
          companyName={pageContext.company} 
          pageContext={pageContext}  
          parametro="telefono" 
          title={pageContext.locale ==="esES" ? "Verificación de Telefóno" : "Cell phone verification"}
        />
      </>
    )}
  />
)

export default VerificacionTelefonicaPage
